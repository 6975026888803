@keyframes rotation
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

@mixin animationConditionsAll
  +motionEnabled
    @content

@mixin animationConditions
  +res-md-min
    +motionEnabled
      @content
